import { useState, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import format from 'date-fns/format';
import { IconButton, Button } from '@material-ui/core';

import { selectCurrentContact } from 'slices/contactsSlice';
import { useAppSelector } from 'src/app/hooks';
import styles from 'styles/contactDetails/contactDetails.module.scss';
import WhatsUpIcon from 'icons/whats-app.svg';
import ChatIcon from 'icons/chat.svg';
import PhoneIcon from 'icons/phone.svg';
import MessageIcon from 'icons/message.svg';
import WebIcon from 'icons/web-picker.svg';
import AddressIcon from 'icons/address-picker.svg';
import BirthdayIcon from 'icons/birthday-picker.svg';
import ContactFieldsForm from './ContactFieldsForm';
import Tooltip from 'components/UI/Tooltip';
import useFormatNumber from '../../../app/hooks/ui/useFormatNumber';
import CustomFieldsList from 'src/features2/contactDetails/ui/CustomFieldsList';

interface ContactFieldsProps {
  isReport?: boolean;
  emails?: string[] | null;
  websites?: string[] | null;
  companyEmployees?: string | null;
  companyIndustry?: string | null;
  companyHeadquarters: string | null;
  foundedYear?: { year: string; type: string; day: number; month: number }[] | null;
  phones: string[];
  isWithoutPhoneNumber: boolean;
}

const ContactFields: React.FC<ContactFieldsProps> = ({
  isReport,
  emails,
  websites,
  foundedYear,
  companyEmployees,
  companyIndustry,
  companyHeadquarters,
  phones,
  isWithoutPhoneNumber,
}) => {
  const [openFormPhones, setOpenFormPhones] = useState<boolean>(false);
  const [openFormEmails, setOpenFormEmails] = useState<boolean>(false);
  const [openFormURL, setOpenFormURL] = useState<boolean>(false);
  const [openFormAddress, setOpenFormAddress] = useState<boolean>(false);
  const [openFormEvents, setOpenFormEvents] = useState<boolean>(false);
  const currentContact = useAppSelector(selectCurrentContact);
  const emailFieldRef = useRef(null);
  const phoneFieldRef = useRef(null);
  const addressFieldRef = useRef(null);
  const urlFieldRef = useRef(null);
  const eventsFieldRef = useRef(null);
  const { t } = useTranslation('contact-details');
  const { formatNumber } = useFormatNumber();

  const correctPhonesList = phones ? phones : !isReport ? currentContact.phones : null;
  const correctEmailsList = emails ? emails : currentContact.emails;
  const correctWebsitesList = websites ? websites : currentContact.urls;
  const correctDateList = foundedYear ? foundedYear : currentContact.dates;

  const isEmailFieldVisible = correctEmailsList?.length && correctEmailsList[0] !== null;
  const isWebsitesFieldVisible = correctWebsitesList?.length && correctWebsitesList[0] !== null;
  const isDatesFieldVisible = correctDateList?.length && !!correctDateList[0].year;

  const handleEditPhones = () => {
    setOpenFormPhones(true);
  };
  const handleEditEvents = () => {
    setOpenFormEvents(true);
  };
  const handleEditEmails = () => {
    setOpenFormEmails(true);
  };
  const handleEditURL = () => {
    setOpenFormURL(true);
  };
  const handleEditAddress = () => {
    setOpenFormAddress(true);
  };

  return (
    <>
      {correctPhonesList?.length && !isWithoutPhoneNumber ? (
        <div className={styles.itemContainer} ref={phoneFieldRef}>
          {correctPhonesList.map((phone) => {
            return (
              <div key={phone.phone} className={styles.fieldWrapper}>
                <div className={styles.fieldsInfo}>
                  <span className={styles.fieldLabel}>
                    {isReport ? t('report.field_title_office') : t(`fields.${phone.type}.title`)}
                  </span>
                  <div key={phone.phone} className={styles.fieldValue}>
                    <span className={styles.fieldText}>
                      {formatNumber(phone.phone, phone?.normalized_phone)}
                    </span>
                    {!isReport && (
                      <Tooltip placement={'top'} title={t('tooltips.edit')}>
                        <IconButton onClick={handleEditPhones} className={styles.fieldEditButton}>
                          <CreateOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className={styles.fieldActions}>
                  {!isReport && (
                    <>
                      <a
                        target={'_blank'}
                        href={`https://wa.me/${phone.normalized_phone}`}
                        rel="noreferrer"
                      >
                        <Tooltip placement="top" title={t('tooltips.whatsapp')}>
                          <IconButton className={styles.fieldActionButton}>
                            <WhatsUpIcon />
                          </IconButton>
                        </Tooltip>
                      </a>
                      <a href={`sms://${phone.normalized_phone}`} rel="noreferrer">
                        <Tooltip placement="top" title={t('tooltips.message')}>
                          <IconButton className={styles.fieldActionButton}>
                            <ChatIcon />
                          </IconButton>
                        </Tooltip>
                      </a>
                    </>
                  )}
                  <a href={`tel:${phone.normalized_phone}`}>
                    <Tooltip placement="top" title={t('tooltips.call')}>
                      <IconButton className={styles.fieldActionButton}>
                        <PhoneIcon />
                      </IconButton>
                    </Tooltip>
                  </a>
                </div>
              </div>
            );
          })}
          {!isReport && (
            <ContactFieldsForm
              type={'number'}
              open={openFormPhones}
              setOpen={setOpenFormPhones}
              anchorEl={phoneFieldRef.current}
            />
          )}
        </div>
      ) : (
        !isReport && (
          <>
            <div className={styles.fieldWrapper} ref={phoneFieldRef}>
              <div className={styles.fieldsInfo}>
                <span className={styles.fieldLabel}>{t('fields.mobile.title')}</span>
                <div className={styles.fieldValue}>
                  <Button
                    disableRipple
                    onClick={handleEditPhones}
                    className={styles.fieldsAddButton}
                  >
                    {t('fields.mobile.add')}
                  </Button>
                  {!isReport && (
                    <Tooltip placement={'top'} title={t('tooltips.edit')}>
                      <IconButton onClick={handleEditPhones} className={styles.fieldEditButton}>
                        <CreateOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <ContactFieldsForm
              type={'number'}
              open={openFormPhones}
              setOpen={setOpenFormPhones}
              anchorEl={phoneFieldRef.current}
              isWithoutPhoneNumber={isWithoutPhoneNumber}
            />
          </>
        )
      )}
      <div className={styles.itemContainer} ref={emailFieldRef}>
        {isEmailFieldVisible
          ? correctEmailsList.map((email, index) => {
              const isCompanyEmail = typeof email === 'string';
              const correctEmail = isCompanyEmail ? email : email?.email;

              return (
                <div key={index} className={styles.fieldWrapper}>
                  <div className={styles.fieldsInfo}>
                    <span className={styles.fieldLabel}>{t('duplicates:Email_field')}</span>
                    <div className={styles.fieldValue}>
                      <span className={styles.fieldText}>{correctEmail}</span>
                      {!isReport && (
                        <Tooltip placement={'top'} title={t('tooltips.edit')}>
                          <IconButton onClick={handleEditEmails} className={styles.fieldEditButton}>
                            <CreateOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  {!isReport && (
                    <div className={styles.fieldActions}>
                      <a href={`mailto:${correctEmail}`} target={'_blank'} rel="noreferrer">
                        <Tooltip placement="top" title={t('duplicates:Email_field')}>
                          <IconButton className={styles.fieldActionButton}>
                            <MessageIcon />
                          </IconButton>
                        </Tooltip>
                      </a>
                    </div>
                  )}
                </div>
              );
            })
          : !isReport && (
              <div className={styles.fieldWrapper}>
                <div className={styles.fieldsInfo}>
                  <span className={styles.fieldLabel}>{t('duplicates:Email_field')}</span>
                  <div className={styles.fieldValue}>
                    <Button
                      disableRipple
                      onClick={handleEditEmails}
                      className={styles.fieldsAddButton}
                    >
                      {t('fields.email.add')}
                    </Button>
                    {!isReport && (
                      <Tooltip placement={'top'} title={t('tooltips.edit')}>
                        <IconButton onClick={handleEditEmails} className={styles.fieldEditButton}>
                          <CreateOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            )}
        {!isReport && (
          <ContactFieldsForm
            type={'email'}
            open={openFormEmails}
            setOpen={setOpenFormEmails}
            anchorEl={emailFieldRef.current}
          />
        )}
      </div>
      {companyIndustry && (
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldsInfo}>
            <span className={styles.fieldLabel}>{t(`Industry`)}</span>
            <div className={styles.fieldValue}>
              <span className={styles.fieldText}>{companyIndustry}</span>
            </div>
          </div>
        </div>
      )}
      {companyEmployees && (
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldsInfo}>
            <span className={styles.fieldLabel}>{t(`Employees`)}</span>
            <div className={styles.fieldValue}>
              <span className={styles.fieldText}>{companyEmployees}</span>
            </div>
          </div>
        </div>
      )}
      <div className={styles.itemContainer} ref={urlFieldRef}>
        {isWebsitesFieldVisible
          ? correctWebsitesList.map((url, index) => {
              const isCompanyWebsite = typeof url === 'string';
              const correctWebsite = isCompanyWebsite ? url?.replace('https://', '') : url?.url;

              return (
                <div key={index} className={styles.fieldWrapper}>
                  <div className={styles.fieldsInfo}>
                    <span className={styles.fieldLabel}>
                      {isReport ? t('duplicates:Website_field') : t('duplicates:Website_field')}
                    </span>
                    <div className={styles.fieldValue}>
                      <span className={styles.fieldText}>{correctWebsite}</span>
                      {!isReport && (
                        <Tooltip placement={'top'} title={t('tooltips.edit')}>
                          <IconButton onClick={handleEditURL} className={styles.fieldEditButton}>
                            <CreateOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  {!isReport && (
                    <div className={styles.fieldActions}>
                      <a href={`//${correctWebsite}`} target={'_blank'} rel="noreferrer">
                        <Tooltip placement="top" title={t('duplicates:Website_field')}>
                          <IconButton className={styles.fieldActionButton}>
                            <WebIcon />
                          </IconButton>
                        </Tooltip>
                      </a>
                    </div>
                  )}
                </div>
              );
            })
          : !isReport && (
              <div className={styles.fieldWrapper}>
                <div className={styles.fieldsInfo}>
                  <span className={styles.fieldLabel}>{t('fields.web.title')}</span>
                  <div className={styles.fieldValue}>
                    <Button
                      onClick={handleEditURL}
                      disableRipple
                      className={styles.fieldsAddButton}
                    >
                      {t('fields.web.add')}
                    </Button>
                    {!isReport && (
                      <Tooltip placement={'top'} title={t('tooltips.edit')}>
                        <IconButton onClick={handleEditURL} className={styles.fieldEditButton}>
                          <CreateOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            )}
        {!isReport && (
          <ContactFieldsForm
            type={'url'}
            open={openFormURL}
            setOpen={setOpenFormURL}
            anchorEl={urlFieldRef.current}
          />
        )}
      </div>
      <div className={styles.itemContainer} ref={addressFieldRef}>
        {currentContact.addresses?.length
          ? currentContact.addresses.map((address) => (
              <div key={address.full_address} className={styles.fieldWrapper}>
                <div className={styles.fieldsInfo}>
                  <span className={styles.fieldLabel}>{t(`fields.${address.type}.title`)}</span>
                  <div className={styles.fieldValue}>
                    <span className={styles.fieldText}>{address.full_address}</span>
                    {!isReport && (
                      <Tooltip placement={'top'} title={t('tooltips.edit')}>
                        <IconButton onClick={handleEditAddress} className={styles.fieldEditButton}>
                          <CreateOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div className={styles.fieldActions}>
                  <a
                    href={`https://maps.google.com/?q=${address.full_address}`}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <Tooltip placement="top" title={t('tooltips.navigate')}>
                      <IconButton className={styles.fieldActionButton}>
                        <AddressIcon />
                      </IconButton>
                    </Tooltip>
                  </a>
                </div>
              </div>
            ))
          : !isReport && (
              <div className={styles.fieldWrapper}>
                <div className={styles.fieldsInfo}>
                  <span className={styles.fieldLabel}>{t('fields.address.title')}</span>
                  <div className={styles.fieldValue}>
                    <Button
                      onClick={handleEditAddress}
                      disableRipple
                      className={styles.fieldsAddButton}
                    >
                      {t('fields.address.add')}
                    </Button>
                    <Tooltip placement={'top'} title={t('tooltips.edit')}>
                      <IconButton onClick={handleEditAddress} className={styles.fieldEditButton}>
                        <CreateOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            )}
        {!isReport && (
          <ContactFieldsForm
            type={'address'}
            open={openFormAddress}
            setOpen={setOpenFormAddress}
            anchorEl={addressFieldRef.current}
          />
        )}
      </div>
      <div className={styles.itemContainer} ref={eventsFieldRef}>
        {isDatesFieldVisible
          ? correctDateList.map(({ year, type, month, day }, index) => {
              return (
                <div key={index} className={styles.fieldWrapper}>
                  <div className={styles.fieldsInfo}>
                    <span className={styles.fieldLabel}>
                      {isReport ? t('report.field_title_founded') : type}
                    </span>
                    <div className={styles.fieldValue}>
                      <span className={styles.fieldText}>
                        {!isReport ? format(new Date(year, month, day), 'd  MMM yyyy') : year}
                      </span>
                      {!isReport && (
                        <Tooltip placement={'top'} title={t('tooltips.edit')}>
                          <IconButton onClick={handleEditEvents} className={styles.fieldEditButton}>
                            <CreateOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  {!isReport && (
                    <div className={styles.fieldActions}>
                      <Tooltip placement={'top'} title={t('tooltips.event')}>
                        <IconButton className={styles.fieldActionButton}>
                          <BirthdayIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                </div>
              );
            })
          : !isReport && (
              <div className={styles.fieldWrapper}>
                <div className={styles.fieldsInfo}>
                  <span className={styles.fieldLabel}>{t('fields.event.title')}</span>
                  <div className={styles.fieldValue}>
                    <Button
                      onClick={handleEditEvents}
                      disableRipple
                      className={styles.fieldsAddButton}
                    >
                      {t('fields.event.add')}
                    </Button>
                    <Tooltip placement={'top'} title={t('tooltips.edit')}>
                      <IconButton onClick={handleEditEvents} className={styles.fieldEditButton}>
                        <CreateOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            )}
        {!isReport && (
          <ContactFieldsForm
            type={'event'}
            open={openFormEvents}
            setOpen={setOpenFormEvents}
            anchorEl={eventsFieldRef.current}
          />
        )}
      </div>
      {companyHeadquarters && (
        <div className={styles.fieldWrapper}>
          <div className={styles.fieldsInfo}>
            <span className={styles.fieldLabel}>{t(`Headquarters`)}</span>
            <div className={styles.fieldValue}>
              <span className={styles.fieldText}>{companyHeadquarters}</span>
            </div>
          </div>
        </div>
      )}
      <CustomFieldsList contactUuid={currentContact.uuid} />

      {/* <Button className={styles.addButton}>{t('fields.add')}</Button> */}
    </>
  );
};
export default ContactFields;
