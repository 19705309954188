import { Menu } from '@material-ui/core';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import Contact from 'types/contact';
import TagIcon from 'icons/tag-black.svg';
import MessageIcon from 'icons/message-black.svg';
import TasksIcon from 'icons/tasks-black.svg';
import QuoteIcon from 'icons/quote-black.svg';
import HideIcon from 'icons/hide.svg';
import RemoveIcon from 'icons/red-thrash.svg';
import MoveIcon from 'icons/move-icon.svg';
import ArchiveIcon from 'icons/archive-red.svg';
import styles from './styles.module.scss';
import DialogItem from './components/DialogItem';
import TagsDialog from '../TagDialog/TagsDialog';
import MessageDialog from '../MessageDialog';
import useTaskDrawer from 'src/features/tasks/TaskDrawer/useTaskDrawer';
import { useProposalDrawer } from 'src/features/proposals/ProposalDrawer';
import DialogTypes from 'src/enums/DialogTypes';
import usePreparedDialogs from 'src/app/hooks/ui/usePreparedDialogs';
import { useDispatch } from 'react-redux';
import { deleteItems } from 'slices/tagsBoardSlice';
import MoveToModal from 'components/Modals/MoveToModal';
import { useAppSelector } from 'src/app/hooks';
import { makeSelectProposalsByContact } from 'slices/proposalsSlice';
import useCreateProposalFromBoardItem from 'src/features/calls/Board/Proposals/useCreateProposalFromBoardItem';
import ArchiveToast from 'components/Modals/MoveToModal/components/ArchiveToast';
import useCreateProposalFromCall from 'src/features/calls/Board/Proposals/useCreateProposalFromCall';
import { useTasksLimits } from 'src/features2/billing/ui/limitsAndOverlays/limits/useLimits';

type TPosition = {
  x: number;
  y: number;
};

type TTagColumnData = {
  columnTitle: string;
  columnId: string;
  itemId: string;
};

type TCallsBoardData = {
  itemId: string;
  columnId?: string;
  isArchiveToast?: boolean;
};

interface IProps {
  position: TPosition;
  onClose: () => void;
  contactId: Contact['uuid'];
  tagColumnData?: TTagColumnData;
  callsBoardData?: TCallsBoardData;
}

const ContactContextDialog: React.FC<IProps> = ({
  position,
  onClose,
  contactId,
  tagColumnData,
  callsBoardData,
}) => {
  const { t } = useTranslation('dialogs');
  const dispatch = useDispatch();
  const { openCreateForm: openCreateTaskForm } = useTaskDrawer();
  const { openCreateForm: openCreateQuoteForm, close } = useProposalDrawer();
  const { showDialog } = usePreparedDialogs();
  const [labelsDialogAnchorEl, setLabelsDialogAnchorEl] = useState<HTMLLIElement>(null);
  const [messageDialogAnchorEl, setMessageDialogAnchorEl] = useState<HTMLLIElement>(null);
  const [moveDialogAnchorEl, setMoveDialogAnchorEl] = useState<HTMLLIElement>(null);
  const selectProposalsByContact = useMemo(makeSelectProposalsByContact, []);
  const { checkLimits } = useTasksLimits();
  const proposals = useAppSelector((state) => selectProposalsByContact(state, contactId));
  const isCallsMenu = !!callsBoardData;

  const onEditLabels: React.MouseEventHandler<HTMLLIElement> = ({ currentTarget }) =>
    setLabelsDialogAnchorEl(currentTarget);

  const onMessage: React.MouseEventHandler<HTMLLIElement> = ({ currentTarget }) =>
    setMessageDialogAnchorEl(currentTarget);

  const onMoveTo: React.MouseEventHandler<HTMLLIElement> = ({ currentTarget }) =>
    setMoveDialogAnchorEl(currentTarget);

  const onAddTask = () => {
    if (checkLimits()) {
      openCreateTaskForm({
        closeAfterSave: true,
        defaultValues: {
          contactId,
        },
      });
    } else {
      onClose();
    }
  };

  const onSendQuote = () =>
    openCreateQuoteForm({
      defaultValues: {
        contactId,
      },
      onSuccess: close,
      type: 'Move to',
    });

  const onDontShow = () =>
    showDialog({
      type: DialogTypes.DONT_SHOW_IN_LEADER_DIALOG,
      dialogProps: {
        contactId,
        callback: onClose,
      },
    });

  const onRemove = () => {
    dispatch(
      deleteItems({ itemsUuids: [tagColumnData.itemId], columnUuid: tagColumnData.columnId })
    );
    onClose();
  };

  const createProposalFromBoardItem = useCreateProposalFromBoardItem('Move to');
  const createProposalFromCall = useCreateProposalFromCall('Action Drawer');
  return (
    <Menu
      open={!!position}
      anchorPosition={position && { top: position.y, left: position.x }}
      anchorReference="anchorPosition"
      onClose={onClose}
      classes={{ paper: styles.Menu }}
    >
      {isCallsMenu ? (
        <DialogItem
          icon={<MoveIcon className={styles.MoveIcon} />}
          text={t('contact_context_dialog.move_to')}
          onClick={onMoveTo}
        />
      ) : (
        <DialogItem
          icon={<TagIcon />}
          text={t('contact_context_dialog.edit_labels')}
          onClick={onEditLabels}
        />
      )}
      <DialogItem
        isExpanding
        icon={<MessageIcon />}
        text={t('contact_context_dialog.message.title')}
        onMouseOver={onMessage}
      />
      <DialogItem
        icon={<TasksIcon />}
        text={t('contact_context_dialog.add_task')}
        onClick={onAddTask}
      />
      <DialogItem
        icon={<QuoteIcon />}
        text={t('duplicates:Send_quote_action')}
        onClick={onSendQuote}
      />
      {tagColumnData ? (
        <DialogItem
          isRedText
          icon={<RemoveIcon />}
          text={t('contact_context_dialog.remove_from', { columnTitle: tagColumnData.columnTitle })}
          onClick={onRemove}
        />
      ) : callsBoardData?.isArchiveToast ? (
        <DialogItem isRedText className={styles.ArchiveToastWrapper} icon={<ArchiveIcon />}>
          <ArchiveToast
            className={styles.ArchiveToast}
            contactUuid={contactId}
            onClose={onClose}
            action={'From right click'}
          />
        </DialogItem>
      ) : (
        <DialogItem
          isRedText
          icon={<HideIcon />}
          text={t('contact-details:actions.more.dont_show_button_text')}
          onClick={onDontShow}
        />
      )}
      {!isCallsMenu && (
        <TagsDialog
          contactId={contactId}
          action_type={'From right click'}
          anchorEl={labelsDialogAnchorEl}
          setAnchorEl={setLabelsDialogAnchorEl}
        />
      )}
      <MessageDialog
        contactId={contactId}
        anchorEl={messageDialogAnchorEl}
        onClose={() => setMessageDialogAnchorEl(null)}
      />
      {isCallsMenu && (
        <MoveToModal
          onSendProposal={() => {
            if (callsBoardData.columnId) {
              createProposalFromBoardItem(callsBoardData.itemId, callsBoardData.columnId);
            } else {
              createProposalFromCall(callsBoardData.itemId);
            }
            setMoveDialogAnchorEl(null);
            onClose();
          }}
          action={'From right click'}
          proposalSelected={!!proposals?.length}
          itemUuid={callsBoardData.itemId}
          columnUuid={callsBoardData.columnId}
          anchorEl={moveDialogAnchorEl}
          contactUuid={contactId}
          onClose={() => setMoveDialogAnchorEl(null)}
        />
      )}
    </Menu>
  );
};

export default ContactContextDialog;
